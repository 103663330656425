.chip{
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 4px 8px;
    color: #fff;
    background-color: gray;
    border-radius: 40px;
    font-size: 14px;
    width: fit-content;
}
.chip svg{
    width: 16px;
    height: 16px;
    cursor: pointer;
}
