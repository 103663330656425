.board{
    min-width: 290px;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
}

.board_top{
    display: flex;
    justify-content: space-between;
}
.board_top_title{
    display: flex;
    align-items: center;
    font-weight: bold;
    gap: 5px;
}
.board_top_title span{
    color: gray;
}

.board_cards{
    flex: 1;
    height: 100%;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    border-bottom: 10px;
    overflow-y: auto;
}


.board_cards_add{
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 1px 2px 0 1px rgba(0,0,0,0.15);
}

.board_cards_add:hover{
    box-shadow: 1px 2px 0 1px rgba(0,0,0,0.15);
}
.board_top_more{
    position: relative;
    cursor: pointer;
}
.board_dropdown{
    background-color: #fff;
    width: 130px;
    padding: 10px;
    border-radius: 3px;
    box-shadow: 1px 0 20px rgba(0,0,0,.05);
}
.board_dropdown p{
    cursor: pointer;
}