.card{
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 10px; 
}

.card_top{
    display: flex;
    gap: 5px;
}

.card_label{
    flex: 1;
    display: flex;
    gap: 10px;
}

.card_top svg{
    opacity: 0;
    transition: 200ms;
}

.card:hover .card_top svg{
    opacity: 1;
}

.card_title{
    font-weight: bold;
}
.card_footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card_footer p{
    display: flex;
    gap: 5px;
    align-items: center;
}
.card_footer svg{
    height: 16px;
    width: 16px;
}

.card_top_more{
    position: relative;
    cursor: pointer;
}
.card_dropdown{
    background-color: #fff;
    width: 130px;
    padding: 10px;
    border-radius: 3px;
    box-shadow: 1px 0 20px rgba(0,0,0,.05);
}
.card_dropdown p{
    cursor: pointer;
}