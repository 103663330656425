.custom-scroll::-webkit-scrollbar{
  width: 8px;
}

.custom-scroll::-webkit-scrollbar-thumb{
  border-radius: 30px;
  border: 1px solid #ccc;
  background-color: #fff;
}

.custom-scroll::-webkit-scrollbar-thumb:hover{
  background-color: gray;
}


.app{
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.app_navbar{
  width: 100%;
  padding: 20px 30px;
  border-bottom: 1px solid #ccc;
}

.app_outer{
  flex: 1;
  width: 100%;
  overflow-x: auto;
}

.app_boards{
  min-width: fit-content;
  padding: 20px;
  padding-bottom: 0;
  display: flex;
  gap: 30px;
  height: 100%;
}
.app_boards_board{
  width: 290px;
}

.app_boards_board_add{
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 1px 2px 0 1px rgba(0,0,0,0.15);
}

.app_boards_board_add:hover{
  box-shadow: 1px 2px 0 1px rgba(0,0,0,0.15);
}